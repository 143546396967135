import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Formik } from 'formik';
import Common from 'us.common';
import { Link, useLocation } from 'react-router-dom';
import {
	ICaseList,
	ISummaryAmount,
} from 'us.collection.debtor/components/DebtorBasicInfo/Interface';
import { debtor } from 'us.collection.debtor/actions';
import { SimilarDebtor } from 'us.collection.debtor/Interfaces/Debtor';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './CaseList.scss';
import { IOnFilter, IOnSort } from 'us.common/components/antd/TableTree';
import { appInsights } from 'us.helper';
import {
	DEFAULT_SIMILAR_DEBTOR_FILTERS,
	DEFAULT_SUMMARY_DATA,
	DEFAULT_TABLE_INFO,
	DateValues,
	DebtorFilterBy,
	DebtorType,
} from '../../Constants';
import { RootState } from 'us.helper/types';
import { INavigationData } from 'us.collection/interfaces';
import { URLType } from 'us.collection/constants';
import { CaseType } from 'us.helper/types/enums';
import moment from "moment";
import { $DateLabel } from "us.common/components";
const {
	$Form,
	$Typography,
	$Tag,
	$Skeleton,
	$Tooltip,
	$Switch,
	$AmountLabel,
	$TableTree,
	$CheckboxGroup,
} = Common.Components;

const { Text } = $Typography;

const CaseList: React.FC<ICaseList & PropsFromRedux> = (props) => {
	const { t } = useTranslation(
		['US.COLLECTION.DEBTOR', 'US.COLLECTION.COMMON'],
		{
			useSuspense: true,
		}
	);

	const {
		isFetchingDebtorCaseInfo,
		debtorCaseInformations,
		metaData,
		debtorType,
		getCaseInformation,
		onClose,
	} = props;

	const location = useLocation();
	const state = location.state as INavigationData;

	const { caseId, debtorId } = metaData.data ?? {};

	const [searchFilters, setSearchFilters] = useState<SimilarDebtor>(
		DEFAULT_SIMILAR_DEBTOR_FILTERS
	);

	const [viewCloseCase, setViewCloseCase] = useState(false);
	const [openCases, setOpenCase] = useState([]);
	const [closeCases, setCloseCase] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableInfo, setTableInfo] = useState<{
		expandKeys: any[];
		isExpandColumns: boolean;
	}>(DEFAULT_TABLE_INFO);
	const [summaryData, setSummaryData] =
		useState<Array<ISummaryAmount>>(DEFAULT_SUMMARY_DATA);
	const { expandKeys } = tableInfo;

	useEffect(() => {
		if (metaData && debtorId && getCaseInformation) {
			getCaseInformation(searchFilters, debtorId);
		}
	}, [metaData, searchFilters]);

	useEffect(() => {
		if (
			!_.isEmpty(debtorCaseInformations) &&
			debtorCaseInformations[0]?.caseId !== 0
		) {
			let openCase: any = [];
			let closeCase: any = [];
			debtorCaseInformations?.map((item: any) => {
				item.caseState === '0'
					? closeCase.push(item)
					: openCase.push(item);
			});
			let temData: any = [];
			let summaryTem: any = [];
			try {
				Object.values(_.groupBy(openCase, 'pid')).map(
					(opr: any) => {
						if (opr.length > 1) {
							temData.push({
								key: `${opr[0].pid}_${opr[0].creditorName}`,
								RowGroup: true,
								caseNumber: `${opr[0].pid} - ${opr[0].creditorName}`,
								cid: '',
								mainAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.mainAmount
										)
										.map(
											Number
										)
								),
								courtFee: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.courtFee
										)
										.map(
											Number
										)
								),
								collectionFee:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.collectionFee
											)
											.map(
												Number
											)
									),
								caseCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.caseCost
										)
										.map(
											Number
										)
								),
								otherCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.otherCost
										)
										.map(
											Number
										)
								),
								interestAmount:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.interestAmount
											)
											.map(
												Number
											)
									),
								paidAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.paidAmount
										)
										.map(
											Number
										)
								),
								balance: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.balance
										)
										.map(
											Number
										)
								).toFixed(2),
								workflowState:
									'',
								children: opr,
							});
						} else {
							temData.push({
								key: `${opr[0].pid}_${opr[0].creditorName}`,
								RowGroup: true,
								caseNumber: `${opr[0].pid} - ${opr[0].creditorName}`,
								cid: '',
								mainAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.mainAmount
										)
										.map(
											Number
										)
								),
								courtFee: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.courtFee
										)
										.map(
											Number
										)
								),
								collectionFee:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.collectionFee
											)
											.map(
												Number
											)
									),
								caseCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.caseCost
										)
										.map(
											Number
										)
								),
								otherCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.otherCost
										)
										.map(
											Number
										)
								),
								interestAmount:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.interestAmount
											)
											.map(
												Number
											)
									),
								paidAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.paidAmount
										)
										.map(
											Number
										)
								),
								balance: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.balance
										)
										.map(
											Number
										)
								).toFixed(2),
								workflowState:
									'',
								children: opr,
							});
						}
					}
				);

				summaryTem.push({
					mainAmount: _.sum(
						temData
							.map(
								(o: any) =>
									o.mainAmount
							)
							.map(Number)
					),
					courtAmount: _.sum(
						temData
							.map(
								(o: any) =>
									o.courtFee
							)
							.map(Number)
					),
					otherAmount: _.sum(
						temData
							.map(
								(o: any) =>
									o.otherCost
							)
							.map(Number)
					),
					collectionFee: _.sum(
						temData
							.map(
								(o: any) =>
									o.collectionFee
							)
							.map(Number)
					),
					caseCost: _.sum(
						temData
							.map(
								(o: any) =>
									o.caseCost
							)
							.map(Number)
					),
					interestAmount: _.sum(
						temData
							.map(
								(o: any) =>
									o.interestAmount
							)
							.map(Number)
					),
					paidAmount: _.sum(
						temData
							.map(
								(o: any) =>
									o.paidAmount
							)
							.map(Number)
					),
					balance: _.sum(
						temData
							.map(
								(o: any) =>
									o.balance
							)
							.map(Number)
					).toFixed(2),
				});
				setSummaryData(summaryTem);
				setOpenCase(openCase);
				setCloseCase(closeCase);
				handleSetExpandKeys(temData);
			} catch (error) {
				appInsights.trackException(
					`Debtor Information Case List view data preparation error - ${error}`
				);
				setSummaryData(summaryTem);
				setOpenCase(openCase);
				setCloseCase(closeCase);
				handleSetExpandKeys(temData);
			}
		} else {
			setTableData([]);
			setOpenCase([]);
			setCloseCase([]);
			setTableInfo({ ...tableInfo, expandKeys: [] });
		}
	}, [debtorCaseInformations, metaData]);

	useEffect(() => {
		onChangeView(viewCloseCase);
	}, [openCases]);

	const onChangeView = (value: any) => {
		let temData: any = [];
		let summaryTem: any = [];
		try {
			if (value && debtorCaseInformations[0]?.caseId !== 0) {
				Object.values(
					_.groupBy(debtorCaseInformations, 'pid')
				).map((opr: any) => {
					if (opr.length > 1) {
						temData.push({
							key: `${opr[0].pid}_${opr[0].creditorName}`,
							RowGroup: true,
							caseNumber: `${opr[0].pid} - ${opr[0].creditorName}`,
							cid: '',
							mainAmount: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.mainAmount
									)
									.map(
										Number
									)
							),
							courtFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.courtFee
									)
									.map(
										Number
									)
							),
							collectionFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.collectionFee
									)
									.map(
										Number
									)
							),
							otherCost: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.otherCost
									)
									.map(
										Number
									)
							),
							caseCost: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.caseCost
									)
									.map(
										Number
									)
							),
							interestAmount: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.interestAmount
									)
									.map(
										Number
									)
							),
							paidAmount: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.paidAmount
									)
									.map(
										Number
									)
							),
							balance: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.balance
									)
									.map(
										Number
									)
							),
							workflowState: '',
							children: opr,
						});
					} else {
						temData.push({
							key: `${opr[0].pid}_${opr[0].creditorName}`,
							RowGroup: true,
							caseNumber: `${opr[0].pid} - ${opr[0].creditorName}`,
							cid: '',
							mainAmount: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.mainAmount
									)
									.map(
										Number
									)
							),
							courtFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.courtFee
									)
									.map(
										Number
									)
							),
							collectionFee: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.collectionFee
									)
									.map(
										Number
									)
							),
							caseCost: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.caseCost
									)
									.map(
										Number
									)
							),
							otherCost: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.otherCost
									)
									.map(
										Number
									)
							),
							interestAmount: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.interestAmount
									)
									.map(
										Number
									)
							),
							paidAmount: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.paidAmount
									)
									.map(
										Number
									)
							),
							balance: _.sum(
								opr
									.map(
										(
											o: any
										) =>
											o.balance
									)
									.map(
										Number
									)
							),
							workflowState: '',
							children: opr,
						});
					}
				});
			} else {
				Object.values(_.groupBy(openCases, 'pid')).map(
					(opr: any) => {
						if (opr.length > 1) {
							temData.push({
								key: `${opr[0].pid}_${opr[0].creditorName}`,
								RowGroup: true,
								caseNumber: `${opr[0].pid} - ${opr[0].creditorName}`,
								cid: '',
								mainAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.mainAmount
										)
										.map(
											Number
										)
								),
								courtFee: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.courtFee
										)
										.map(
											Number
										)
								),
								collectionFee:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.collectionFee
											)
											.map(
												Number
											)
									),
								caseCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.caseCost
										)
										.map(
											Number
										)
								),
								otherCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.otherCost
										)
										.map(
											Number
										)
								),
								interestAmount:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.interestAmount
											)
											.map(
												Number
											)
									),
								paidAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.paidAmount
										)
										.map(
											Number
										)
								),
								balance: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.balance
										)
										.map(
											Number
										)
								).toFixed(2),
								workflowState:
									'',
								children: opr,
							});
						} else {
							temData.push({
								key: `${opr[0].pid}_${opr[0].creditorName}`,
								RowGroup: true,
								caseNumber: `${opr[0].pid} - ${opr[0].creditorName}`,
								cid: '',
								mainAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.mainAmount
										)
										.map(
											Number
										)
								),
								courtFee: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.courtFee
										)
										.map(
											Number
										)
								),
								collectionFee:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.collectionFee
											)
											.map(
												Number
											)
									),
								caseCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.caseCost
										)
										.map(
											Number
										)
								),
								otherCost: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.otherCost
										)
										.map(
											Number
										)
								),
								interestAmount:
									_.sum(
										opr
											.map(
												(
													o: any
												) =>
													o.interestAmount
											)
											.map(
												Number
											)
									),
								paidAmount: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.paidAmount
										)
										.map(
											Number
										)
								),
								balance: _.sum(
									opr
										.map(
											(
												o: any
											) =>
												o.balance
										)
										.map(
											Number
										)
								).toFixed(2),
								workflowState:
									'',
								children: opr,
							});
						}
					}
				);
			}

			summaryTem.push({
				mainAmount: _.sum(
					temData
						.map((o: any) => o.mainAmount)
						.map(Number)
				),
				courtAmount: _.sum(
					temData
						.map((o: any) => o.courtFee)
						.map(Number)
				),
				otherAmount: _.sum(
					temData
						.map((o: any) => o.otherCost)
						.map(Number)
				),
				collectionFee: _.sum(
					temData
						.map(
							(o: any) =>
								o.collectionFee
						)
						.map(Number)
				),
				caseCost: _.sum(
					temData
						.map((o: any) => o.caseCost)
						.map(Number)
				).toFixed(2),
				interestAmount: _.sum(
					temData
						.map(
							(o: any) =>
								o.interestAmount
						)
						.map(Number)
				),
				paidAmount: _.sum(
					temData
						.map((o: any) => o.paidAmount)
						.map(Number)
				),
				balance: _.sum(
					temData
						.map((o: any) => o.balance)
						.map(Number)
				),
			});
			setSummaryData(summaryTem);
			setTableData(temData);
			setViewCloseCase(value);
			handleSetExpandKeys(temData);
		} catch (error) {
			appInsights.trackException(
				`Debtor Information Case List view data preparation error on view changes clicking - ${error}`
			);
			setSummaryData(summaryTem);
			setTableData(temData);
			setViewCloseCase(value);
			handleSetExpandKeys(temData);
		}
	};

	const handleSetExpandKeys = (keys: any[]) => {
		setTableInfo({
			...tableInfo,
			expandKeys: keys.map((o: any) => o.key),
		});
	};

	const onTableRowExpand = (expand: any, record: any) => {
		if (expand) {
			setTableInfo({
				...tableInfo,
				expandKeys: [...expandKeys, record.key],
			});
		} else {
			setTableInfo({
				...tableInfo,
				expandKeys: [
					...expandKeys.filter(
						(item: any) =>
							item !== record.key
					),
				],
			});
		}
	};

	const column: any = () => {
		const columns: any = [
			{
				title: () => {
					return (
						<div>
							<div>
								{t(
									'US.COLLECTION.DEBTOR:CASELIST.CASE_NO'
								)}
							</div>
							<div className='d-flex'>
								<$Tag className='tag-status-active'>
									{`${openCases?.length}`}{' '}
									{t(
										'US.COLLECTION.DEBTOR:CASELIST.OPEN_CASES'
									)}
								</$Tag>
								{viewCloseCase && (
									<$Tag className='tag-status-close'>
										{`${closeCases?.length}`}{' '}
										{t(
											'US.COLLECTION.DEBTOR:CASELIST.CLOSE_CASES'
										)}
									</$Tag>
								)}
							</div>
						</div>
					);
				},
				dataIndex: 'caseNumber',
				key: 'caseNumber',
				width: 250,
				ellipsis: true,
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
				customRenderParent: (
					text: any,
					record: any
				) => {
					return {
						children: (
							<$Tooltip
								title={text}
								overlayClassName='text-nowrap cl-tooltip-name'
								style={{
									maxWidth: 'auto',
								}}>
								<div
									className='text-truncate'
									style={{
										maxWidth: '180px',
									}}>
									{text}
								</div>
							</$Tooltip>
						),
						props: {
							colSpan: 2,
						},
					};
				},
				customRenderChild: (text: any, record: any) => {
					const {
						caseId,
						caseNumber,
						caseType,
						caseState,
					} = record ?? {};
					return (
						<div>
							<div className='d-flex align-items-center'>
								<$Tooltip
									title={
										caseState ===
										'1'
											? t(
													'US.COLLECTION.DEBTOR:CASELIST.OPEN_CASE'
											  )
											: t(
													'US.COLLECTION.DEBTOR:CASELIST.CLOSE_CASE'
											  )
									}>
									<div
										className={`row-tag tag-status-${
											caseState ===
											'1'
												? 'open'
												: 'close'
										}`}></div>
								</$Tooltip>
								<Link
									to={{
										...location,
										pathname: `/case/${caseNumber}`,
										state: {
											...state,
											subCaseId: caseId,
											caseNo: caseNumber,
											caseType,
											id: caseId,
											currentTab:
												caseType ==
												CaseType.C
													? URLType.CASE
													: URLType.SUBCASE,
										},
									}}
									onClick={() =>
										onClose()
									}
									data-testid='debtor-caseList-caseNavigation'>
									{text}
								</Link>
							</div>
						</div>
					);
				},
			},

			{
				title: t('US.COLLECTION.DEBTOR:CASELIST.CID'),
				dataIndex: 'cid',
				key: 'cid',
				width: 120,
				className: 'text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.COMMON:COMMON.MAINAMOUNT'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.mainAmount
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'mainAmount',
				key: 'mainAmount',
				align: 'right',
				width: 150,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<strong>
							<$AmountLabel
								value={
									record?.mainAmount
								}
							/>
						</strong>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={
								record?.mainAmount
							}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.COLLECTION_FEE'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.collectionFee
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'collectionFee',
				key: 'collectionFee',
				align: 'right',
				width: 150,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<$AmountLabel
							value={
								record?.collectionFee
							}
						/>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={
								record?.collectionFee
							}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.COURT_FEE'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.courtFee
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'courtFee',
				key: 'courtFee',
				align: 'right',
				width: 130,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<$AmountLabel
							value={record?.courtFee}
						/>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={record?.courtFee}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.CASE_COST'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.caseCost
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'caseCost',
				key: 'caseCost',
				align: 'right',
				width: 130,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<$AmountLabel
							value={record?.caseCost}
						/>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={record?.caseCost}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.OTHER_COST'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.otherCost
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'otherCost',
				key: 'otherCost',
				align: 'right',
				width: 130,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<$AmountLabel
							value={
								record?.otherCost
							}
						/>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={
								record?.otherCost
							}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.INTEREST'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.interestAmount
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'interestAmount',
				key: 'interestAmount',
				align: 'right',
				width: 130,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<$AmountLabel
							value={
								record?.interestAmount
							}
						/>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={
								record?.interestAmount
							}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.PAID'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.paidAmount
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'paidAmount',
				key: 'paidAmount',
				align: 'right',
				width: 130,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<strong>
							<$AmountLabel
								value={
									record?.paidAmount
								}
							/>
						</strong>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={
								record?.paidAmount
							}
						/>
					);
				},
			},
			{
				title: () => (
					<>
						<div>
							{t(
								'US.COLLECTION.DEBTOR:CASELIST.BALANCE'
							)}
						</div>
						<div className='header-custom-amount'>
							<Text type='success'>
								{
									<$AmountLabel
										value={Number(
											summaryData[0]
												.balance
										)}
									/>
								}
							</Text>
						</div>
					</>
				),
				dataIndex: 'balance',
				key: 'balance',
				align: 'right',
				width: 130,
				className: 'right-has-sort right-has-filter text-nowrap',
				customSorter: (a: any, b: any) => a - b,
				customFilter: 'amount',
				customRenderParent: (
					text: any,
					record: any
				) => {
					return (
						<strong>
							<$AmountLabel
								value={
									record?.balance
								}
							/>
						</strong>
					);
				},
				customRenderChild: (text: any, record: any) => {
					return (
						<$AmountLabel
							value={record?.balance}
						/>
					);
				},
			},
			{
				title: t(
					'US.COLLECTION.DEBTOR:CASELIST.CASE_STATE'
				),
				dataIndex: 'workflowState',
				key: 'workflowState',
				width: 300,
				ellipsis: true,
				customSorter: (a: any, b: any) =>
					a?.localeCompare(b),
				customFilter: true,
				customRenderParent: (
					text: any,
					record: any
				) => {
					return <></>;
				},
				customRenderChild: (text: any, record: any) => {
					return record.workflowState;
				},
			},
		];
		const expandColumns: any = [
			{
				title: t('US.COLLECTION.DEBTOR:CASELIST.NAME'),
				dataIndex: 'debtorName',
				key: 'debtorName',
				width: 300,
				ellipsis: true,
				customSorter: (a: any, b: any) =>
					a?.localeCompare(b),
				customFilter: true,
				customRenderParent: () => {
					return <></>;
				},
				customRenderChild: (text: any, record: any) => {
					return record.debtorName;
				},
			},
			{
				title: t(
					'US.COLLECTION.DEBTOR:CASELIST.PERSON_NO'
				),
				dataIndex: 'debtorPersonNo',
				key: 'debtorPersonNo',
				width: 150,
				ellipsis: true,
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
				customRenderParent: () => {
					return <></>;
				},
				customRenderChild: (text: any, record: any) => {
					return record.debtorPersonNo;
				},
			},
			{
				title: t(
					'US.COLLECTION.DEBTOR:CASELIST.ZIP_CODE'
				),
				dataIndex: 'debtorZipCode',
				key: 'debtorZipCode',
				width: 150,
				ellipsis: true,
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
				customRenderParent: () => {
					return <></>;
				},
				customRenderChild: (text: any, record: any) => {
					return record.debtorZipCode;
				},
			},
			{
				title: t(
					'US.COLLECTION.DEBTOR:CASELIST.CLOSED_DATE'
				),
				dataIndex: 'closedDate',
				key: 'closedDate',
				width: 150,
				ellipsis: true,
				customSorter: (a: any, b: any) => a - b,
				customFilter: true,
				customRenderParent: () => {
					return <></>;
				},
				customRenderChild: (text: any, record: any) => {
					return record?.closedDate && 
					moment
					.utc(record.closedDate)
					.isAfter(moment.utc(DateValues.minDate)) && (
					<$DateLabel value={record?.closedDate} />
					);
				},
			},
		];

		return tableInfo.isExpandColumns
			? [...columns, ...expandColumns]
			: [...columns];
	};

	const handleSort: IOnSort = (sortData, dataSource) => {
		try {
			return sortData(
				dataSource.map((i: any) => {
					return {
						...i,
						children: sortData(i.children),
					};
				})
			);
		} catch (error) {
			appInsights.trackException(
				`Debtor Information Case List sort error - ${error}`
			);
			return dataSource;
		}
	};

	const handleFilter: IOnFilter = (searchData, dataSource) => {
		let result: any[] = [];
		try {
			dataSource.flatMap((i: any) => {
				searchData(i?.children)?.length != 0 &&
					result.push({
						...i,
						children:
							searchData(i?.children)
								?.length == 0
								? []
								: searchData(
										i?.children
								  ),
					});
			});
			return result;
		} catch (error) {
			appInsights.trackException(
				`Debtor Information Case List filter error - ${error}`
			);
			return result;
		}
	};

	return (
		<Formik
			initialValues={searchFilters}
			enableReinitialize
			onSubmit={() => {}}>
			{({
				values,
				handleChange,
				handleBlur,
				handleSubmit,
				isSubmitting,
				isValidating,
				resetForm,
				...restProps
			}: any) => (
				<div className='case-list'>
					<$Form onSubmit={handleSubmit}>
						<div
							style={{
								marginBottom:
									'2rem',
							}}>
							<div className='d-flex align-items-start'>
								<div>
									<div>
										<$Switch
											checked={
												values?.isSimilarDebtor
											}
											size='small'
											name='isSimilarDebtor'
											disabled={
												isFetchingDebtorCaseInfo
											}
											onChange={(
												isSimilarDebtor: boolean
											) =>
												setSearchFilters(
													{
														...searchFilters,
														filterBy: isSimilarDebtor
															? searchFilters.filterBy
															: DEFAULT_SIMILAR_DEBTOR_FILTERS.filterBy,
														isSimilarDebtor,
													}
												)
											}
										/>
										<span className='ml-2'>
											{t(
												'US.COLLECTION.DEBTOR:CASELIST.INCLUDE_SIMILAR_DEBTORS_USING'
											)}
										</span>
									</div>
									{values?.isSimilarDebtor && (
										<div
											className='mt-2'
											style={{
												marginLeft: '2.4rem',
												marginRight:
													'3rem',
											}}>
											<$CheckboxGroup
												disabled={
													isFetchingDebtorCaseInfo
												}
												options={[
													{
														label: t(
															'US.COLLECTION.DEBTOR:CASELIST.NAME'
														),
														value: DebtorFilterBy.NAME,
													},
													{
														label:
															debtorType ==
															DebtorType.PERSON
																? t(
																		'US.COLLECTION.DEBTOR:CASELIST.PERSON_NO'
																  )
																: t(
																		'US.COLLECTION.DEBTOR:CASELIST.COMPANY_NO'
																  ),
														value: DebtorFilterBy.PERSON_NO,
													},
													{
														label: t(
															'US.COLLECTION.DEBTOR:CASELIST.ZIP_CODE'
														),
														value: DebtorFilterBy.ZIP_CODE,
													},
												]}
												value={
													values.filterBy
												}
												onChange={(
													checkedValue: any[]
												) =>
													setSearchFilters(
														{
															...searchFilters,
															filterBy: checkedValue,
														}
													)
												}
											/>
										</div>
									)}
								</div>

								<div
									className='d-flex align-items-start'
									style={{
										marginLeft: '4rem',
									}}>
									<$Switch
										size='small'
										checked={
											values?.showClosedCases
										}
										disabled={
											isFetchingDebtorCaseInfo
										}
										name='showClosedCases'
										className='mt-1'
										onChange={(
											value: any
										) =>
											onChangeView(
												value
											)
										}
										data-testid='debtor-caseList-showClosedCases'
									/>
									<span className='ml-2'>
										{t(
											'US.COLLECTION.DEBTOR:CASELIST.SHOW_ALL_CASES'
										)}
									</span>
								</div>
								<div
									className='d-flex align-items-start'
									style={{
										marginLeft: '4rem',
									}}>
									<$Switch
										size='small'
										checked={
											tableInfo?.isExpandColumns
										}
										disabled={
											isFetchingDebtorCaseInfo
										}
										name='isExpandColumns'
										className='mt-1'
										onChange={(
											isExpandColumns: any
										) =>
											setTableInfo(
												{
													...tableInfo,
													isExpandColumns,
												}
											)
										}
									/>
									<span className='ml-2'>
										{t(
											'US.COLLECTION.DEBTOR:CASELIST.EXPAND_ALL'
										)}
									</span>
								</div>
							</div>
						</div>
						<div>
							<$Skeleton
								loading={
									isFetchingDebtorCaseInfo
								}
								active
								paragraph={{
									rows: 2,
								}}>
								<$TableTree
									rowClassName={(
										record: any
									) =>
										caseId?.toString() ===
										record.caseId
											? 'this-case'
											: ''
									}
									rowKey={(
										record: any
									) => {
										return record.hasOwnProperty(
											'children'
										)
											? record.key
											: record.caseId;
									}}
									data={
										tableData
									}
									size='small'
									className='header-custom-tag'
									onSort={
										handleSort
									}
									onFilter={
										handleFilter
									}
									filterOnType={
										true
									}
									resetOnSourceChange={
										true
									}
									bordered
									pagination={{
										defaultPageSize: 15,
									}}
									scroll={{
										x: 1200,
										y: 'calc(100vh - 350px)',
									}}
									columns={column()}
									expandable={{
										defaultExpandAllRows:
											true,
										onExpand: (
											expanded,
											record
										) =>
											onTableRowExpand(
												expanded,
												record
											),
										childrenColumnName:
											'children',
										expandedRowKeys:
											expandKeys,
									}}
									firstColSkipFilterProps={
										-1
									}
								/>
							</$Skeleton>
						</div>
					</$Form>
				</div>
			)}
		</Formik>
	);
};

const mapState = (state: RootState) => {
	const { common, debtorDetail, domainView } = state;
	const { currentDateFormat, currentLanguage, currentCurrency } = common;
	const { metaData } = domainView;
	const { case: debtorCaseInfo } = debtorDetail;
	const { data, isFetching } = debtorCaseInfo;
	return {
		currentDateFormat,
		currentLanguage,
		currentCurrency,
		isFetchingDebtorCaseInfo: isFetching,
		debtorCaseInformations: data,
		metaData,
	};
};

const mapDispatch = {
	getCaseInformation: debtor.case.getByEntInfo,
};
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CaseList);
