import { IFollowUpFilters } from "us.collection.followup/interfaces";

export const followUpFilters: IFollowUpFilters = {
  searchText: "",
  caseStates: {
      open: true,
      sentence: true,
      nextDueDate: true,
      court: true,
      close: true
  },
  entityType: "",
  userOwnedClaims:true,
  actionStatus: "all",
};
